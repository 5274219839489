<template>
  <div class="upload-img-container">
    <IdmDragAndDrop
      :btnText="btnText"
      :dropText="'Recommended size: 140x140px'"
      :fileTypes="fileTypes"
      :progressVal="progressVal"
      @file-uploaded="fileUploaded"
      :data-test="'settings-upload-file-' + type"
    />

    <div class="upload-img-preview" v-if="imgSrc">
      <img :src="imgSrc" alt="logo" :class="type" />

      <IdmButton
        :btnType="'edit'"
        @click="removeFile"
        :data-test="'settings-upload-img-' + type"
      >
        <IdmIcon name="trash" />
      </IdmButton>
    </div>
  </div>
</template>
<script>
import { store } from '@/store';
import { ref, computed } from 'vue';

import { useUploadToS3 } from '../helpers/uploadToS3.js';

export default {
  props: {
    type: {
      type: String,
      default: 'profile',
    },
  },
  setup(props) {
    const btnText = props.type === 'profile' ? ref('Profile image') : ref('Upload logo');
    const fileTypes = ref(['jpg', 'png']);
    const imgSrc =
      props.type === 'profile'
        ? ref(store.getters.getProfileImg)
        : ref(store.getters.getLogo);

    const accountId = computed({
      get() {
        return store.getters.getAccountId;
      },
      set(val) {
        store.commit('setAccountId', val);
      },
    });

    const progressVal = ref(0);

    if (props.type === 'logo') fileTypes.value.push('svg');

    function fileUploaded(file) {
      if (file) {
        console.log('file: ', file);

        const progressInterval = setInterval(() => {
          if (progressVal.value < 100) progressVal.value++;
        }, 50);

        const { request } = useUploadToS3(
          file,
          accountId.value + '/settings',
          process.env.VUE_APP_CUSTOM_CONTENT_URL,
          process.env.VUE_APP_MEDIA_UPLOAD_ENDPOINT,
          localStorage.getItem('auth_userToken')
        );

        request
          .then((link) => {
            console.log('link: ', link);
            if (props.type === 'logo') {
              store.commit('setLogo', link);
            } else {
              store.commit('setProfileImg', link);
            }
            imgSrc.value = link;

            clearInterval(progressInterval);
            progressVal.value = 100;
            setTimeout(() => {
              progressVal.value = 0;
            }, 1500);
          })
          .catch((error) => {
            console.log('error: ', error);

            clearInterval(progressInterval);
            progressVal.value = 100;
            setTimeout(() => {
              progressVal.value = 0;
            }, 100);
          });
      } else if (file === null) {
        if (props.type === 'logo') {
          store.commit('setLogo', null);
        } else {
          store.commit('setProfileImg', null);
        }
        imgSrc.value = null;
      }
    }

    function removeFile() {
      imgSrc.value = null;
      fileUploaded(null);
    }

    return {
      btnText,
      fileTypes,
      imgSrc,
      progressVal,
      fileUploaded,
      removeFile,
    };
  },
};
</script>
<style lang="scss" scoped>
.upload-img-container {
  display: flex;
  align-items: center;
  margin-top: 24px;

  .upload-img-preview {
    width: 104px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    border: 1px solid var(--natural-100);
    border-radius: 8px;
    margin-left: 14px;

    > img {
      width: 40px;
      height: 40px;
      object-fit: cover;
      border-radius: 50%;

      &.logo {
        border-radius: 12px;
      }
    }
  }
}
</style>
