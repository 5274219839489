<template>
  <div class="navbar-container">
    <nav>
      <div
        v-for="(title, index) in titles"
        :key="index"
        :class="{ selected: selected === index }"
        class="navbar-item"
        @click="handleSelected(index)"
        :data-test="'settings-menu-' + title"
      >
        {{ title }}
      </div>
    </nav>
  </div>
</template>
<script>
import { ref } from 'vue';

export default {
  props: {
    sections: Array,
  },
  setup(props) {
    const titles = props.sections.map((section) => {
      if (section.match(/[A-Z]/g).length > 1) {
        return (
          section.split(section.match(/[A-Z]/g)[section.match(/[A-Z]/g).length - 1])[0] +
          ' ' +
          section.match(/[A-Z]/g)[section.match(/[A-Z]/g).length - 1] +
          section.split(section.match(/[A-Z]/g)[section.match(/[A-Z]/g).length - 1])[1]
        );
      } else {
        return section;
      }
    });

    const selected = ref(0);

    function handleSelected(index) {
      selected.value = index;

      const element = document.getElementById('sec-' + index);
      const headerOffset = 100;
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }

    return {
      titles,
      selected,
      handleSelected,
    };
  },
};
</script>
<style lang="scss">
.navbar-container {
  width: 288px;
  height: auto;
  min-height: 100vh;

  background-color: var(--natural-40);
  padding: 32px 23px;

  nav {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: start;
    position: sticky;
    top: 144px;
    left: 0;

    .navbar-item {
      color: var(--dark-500);
      font-size: var(--p2);
      font-weight: 400;
      text-decoration: none;
      margin-bottom: 16px;
      cursor: pointer;

      &.selected {
        color: var(--primary-600);
        font-weight: 700;
      }
    }
  }
}
</style>
