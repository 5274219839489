import { createApp } from 'vue'
import App from './App.vue'
import { store } from './store'
import IdmUI from '@idomoo-hub/library'

createApp(App)
.use(store)
.use(IdmUI, {auth:{
    debug: true,
    api_url: process.env.VUE_APP_API_URL,
    login_url: process.env.VUE_APP_LOGIN_URL,
    logout_url: process.env.VUE_APP_LOGOUT_URL,
    apolloConnectToDevTools: process.env.NODE_ENV !== 'production',
  }})
.mount('#app')
