<template>
  <div class="section-container" data-test="settings-profile-section">
    <h3 class="section-title">Profile</h3>

    <IdmInputWrapper>
      <template #label>
        <label>First Name</label>
      </template>
      <template #input>
        <IdmInput
          :placeholder="'Write your name here'"
          v-model="firstName"
          :value="firstName"
          data-test="settings-first-name-field"
        />
      </template>
    </IdmInputWrapper>

    <IdmInputWrapper>
      <template #label>
        <label>Last Name</label>
      </template>
      <template #input>
        <IdmInput
          :placeholder="'Write your last name here'"
          v-model="lastName"
          :value="lastName"
          data-test="settings-last-name-field"
        />
      </template>
    </IdmInputWrapper>

    <IdmInputWrapper>
      <template #label>
        <label>Email</label>
      </template>
      <template #tooltip>
        <IdmTooltip
          :text="'To enquire about changing your user login email please contact Idomoo support'"
          :onHover="true"
        />
      </template>
      <template #input>
        <IdmInput
          :placeholder="'john.doe@gmail.com'"
          v-model="email"
          :value="email"
          :locked="true"
          data-test="settings-email-field"
        />
      </template>
    </IdmInputWrapper>

    <UploadImage />

    <div class="section-checkbox-container">
      <IdmCheckbox
        :checked="showQR"
        @input="showQR = $event.target.checked"
        data-test="settings-two-step-checkbox"
      />
      <p>Enable 2 step authentication</p>
    </div>

    <p class="section-text" v-if="showQR">
      Scan this QR code with Google or Microsoft Authenticator mobile application.
      <br /><br />
      Each time you sign in, as well as your password, you'll need a verification code
      created using your chosen mobile application.
      <br /><br />
      Don’t forget to save the action by clicking the save button on top.
    </p>

    <div class="qr-code-container" v-if="showQR">
      <div class="loader-container" v-if="!QRValue">
        <IdmLoader class="loader" :width="80" :height="80" />
      </div>

      <img :src="QRValue" alt="qr code" class="qr-code" v-else />
    </div>
  </div>
</template>
<script>
import { store } from '@/store';
import { ref, computed, watch } from 'vue';
import generateQR from '@/gql/generateQR.js';

import UploadImage from './UploadImage.vue';

export default {
  components: {
    UploadImage,
  },
  setup() {
    const QRValue = ref('');
    // const showQR = ref(false);

    const firstName = computed({
      get() {
        return store.getters.getFirstName;
      },
      set(val) {
        store.commit('setFirstName', val);
      },
    });

    const lastName = computed({
      get() {
        return store.getters.getLastName;
      },
      set(val) {
        store.commit('setLastName', val);
      },
    });

    const email = computed({
      get() {
        return store.getters.getEmail;
      },
      set(val) {
        store.commit('setEmail', val);
      },
    });

    const showQR = computed({
      get() {
        return store.getters.getTwoStep;
      },
      set(val) {
        store.commit('setTwoStep', val);
      },
    });

    const twoStepKey = computed({
      get() {
        return store.getters.getTwoStepKey;
      },
      set(val) {
        // QRValue.value = 'otpauth://totp/Yarden%20-%20Settings?secret=' + val;
        store.commit('setTwoStepKey', val);
      },
    });

    function handleQR() {
      generateQR()
        .then((res) => {
          QRValue.value = res.data.generateQR.value;
          console.log('QRValue.value 1: ', QRValue.value);
          console.log('res: ', res);
        })
        .catch((err) => {
          console.log('err: ', err);
        });
    }

    if (showQR.value) {
      console.log('twoStepKey.value: ', twoStepKey.value);
      QRValue.value = twoStepKey.value;
      console.log('QRValue.value 2: ', QRValue.value);
    }

    // watch(twoStepKey, (val) => {
    //   QRValue.value = 'otpauth://totp/Yarden%20-%20Settings?secret=' + val;
    // });

    watch(showQR, (val) => {
      if (val) {
        handleQR();
      } else {
        QRValue.value = '';
      }
    });

    return {
      firstName,
      lastName,
      email,
      QRValue,
      showQR,
    };
  },
};
</script>
<style lang="scss" scoped>
.main-container
  .settings-container
  .settings
  .section-container
  .section-checkbox-container {
  border-bottom: none;
}

.loader-container,
.qr-code {
  width: 120px;
  margin-top: 24px;
}

.loader-container {
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;

  .loader {
    margin: auto;
  }
}

.qr-code {
  position: relative;
  right: 12px;
}
</style>
