import gql from 'graphql-tag';
import { useMutation } from '@vue/apollo-composable'

const q = gql`mutation updateData ($params: JSON) {
    updateData (params: $params) {
      success,
      message, 
    }
}`

export default (params) => {
  const { mutate, onDone, onError } = useMutation(q, {
    update(mutationResult) {
      console.log('mutationResult', mutationResult);
    }
  })

  mutate(params);

  return new Promise((resolve, reject) => {
    onDone(resp => {
      resolve(resp)
    })
    onError(e => {
      reject(e)
    })
  })
}