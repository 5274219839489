<template>
  <div class="section-container" data-test="settings-account-section">
    <h3 class="section-title">Account</h3>

    <IdmInputWrapper>
      <template #label>
        <label>Company</label>
      </template>
      <template #input>
        <IdmInput
          :placeholder="'Google'"
          v-model="company"
          :value="company"
          data-test="settings-company-field"
        />
      </template>
    </IdmInputWrapper>

    <UploadImage :type="'logo'" />

    <IdmInputWrapper class="region">
      <template #label>
        <label>Region</label>
      </template>
      <template #tooltip>
        <IdmTooltip
          :text="'To enquire about changing your account region please contact Idomoo support'"
          :onHover="true"
        />
      </template>
      <template #input>
        <IdmInput :value="regionText" :locked="true" data-test="settings-region-field" />
      </template>
    </IdmInputWrapper>

    <div class="rendering-min-container">
      <p class="section-text">
        Available rendering minutes: <b>{{ renderingMin }}</b>
      </p>

      <IdmTooltip
        :text="'To buy more rendering minutes please contact Idomoo support'"
        :onHover="true"
      />
    </div>
  </div>
</template>
<script>
import { store } from '@/store';
import { ref, computed } from 'vue';

import UploadImage from './UploadImage.vue';

export default {
  components: {
    UploadImage,
  },
  setup() {
    const company = computed({
      get() {
        return store.getters.getCompany;
      },
      set(val) {
        store.commit('setCompany', val);
      },
    });

    const region = computed(() => store.getters.getRegion);
    const regionText = ref('');

    const renderingMin = computed(() => store.getters.getRenderingMin);

    if (region.value === 'us') {
      regionText.value = 'USA and Rest of world';
    } else if (region.value === 'eu') {
      regionText.value = 'Europe';
    } else {
      regionText.value = region.value;
    }

    return {
      company,
      regionText,
      renderingMin,
    };
  },
};
</script>
<style lang="scss" scoped>
.region {
  margin: 32px 0;
}

.rendering-min-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 34px;
}
</style>
