import gql from 'graphql-tag';
import { useMutation } from '@vue/apollo-composable'

const q = gql`mutation generateQR {
    generateQR {
      value 
    }
}`

export default () => {
  const { mutate, onDone, onError } = useMutation(q, {
    update(mutationResult) {
      console.log('mutationResult', mutationResult);
    }
  })

  mutate();

  return new Promise((resolve, reject) => {
    onDone(resp => {
      resolve(resp)
    })
    onError(e => {
      reject(e)
    })
  })
}