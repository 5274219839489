<template>
  <div class="section-container" data-test="settings-api-section">
    <div class="new-key-popup-container" v-show="showPopup">
      <IdmPopup
        :style="{ width: '416px' }"
        :title="'Generate a new API key?'"
        :paragraphs="[
          'Are you sure you want to generate a new API key?',
          'Automations using this key will stop working',
        ]"
      >
        <IdmButton :btnType="'secondary'" :text="'Cancel'" @click="showPopup = false" />
        <IdmButton :btnType="'diverse'" :text="'Generate'" @click="createKey" />
      </IdmPopup>
    </div>
    <h3 class="section-title">API Settings</h3>

    <p class="section-text learn">
      Learn more about our
      <a href="https://developers.idomoo.com/" target="_blank"
        >API on the developer portal</a
      >
    </p>

    <h5 class="section-subtitle">Your API secret key is:</h5>

    <div class="api-key-container">
      <IdmInput
        :placeholder="'Enter key'"
        :secretKey="true"
        :value="apiKey"
        :locked="true"
        :class="{ hideHover }"
        data-test="settings-api-key-field"
      />

      <IdmSuccessText
        :text="'Copied!'"
        :visibilityTime="5000"
        :show="showCopied"
        @click="showCopied++"
      >
        <IdmHoverText>
          <IdmButton
            title="Copy to clipboard"
            :btnType="'secondary'"
            class="api-btn"
            :class="{ copied }"
            @click="copyKey"
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_137_77)">
                <path
                  d="M16.6667 7.5H9.16667C8.24619 7.5 7.5 8.24619 7.5 9.16667V16.6667C7.5 17.5871 8.24619 18.3333 9.16667 18.3333H16.6667C17.5871 18.3333 18.3333 17.5871 18.3333 16.6667V9.16667C18.3333 8.24619 17.5871 7.5 16.6667 7.5Z"
                  stroke="#07354A"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M4.16666 12.5H3.33333C2.8913 12.5 2.46738 12.3244 2.15482 12.0119C1.84226 11.6993 1.66666 11.2754 1.66666 10.8334V3.33335C1.66666 2.89133 1.84226 2.4674 2.15482 2.15484C2.46738 1.84228 2.8913 1.66669 3.33333 1.66669H10.8333C11.2754 1.66669 11.6993 1.84228 12.0118 2.15484C12.3244 2.4674 12.5 2.89133 12.5 3.33335V4.16669"
                  stroke="#07354A"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_137_77">
                  <rect width="20" height="20" fill="white" />
                </clipPath>
              </defs></svg
          ></IdmButton>
        </IdmHoverText>
      </IdmSuccessText>

      <IdmSuccessText
        :text="'New key is available'"
        :visibilityTime="5000"
        :show="apiKey"
      >
        <IdmHoverText>
          <IdmButton
            title="Generate a new key"
            :btnType="'secondary'"
            class="api-btn"
            :class="{ loading, hideHover }"
            @click="showPopup = true"
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19.1667 16.6667V11.6667H14.1667"
                stroke="#07354A"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M0.833336 3.33331V8.33331H5.83334"
                stroke="#07354A"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M17.075 7.49998C16.6524 6.30564 15.9341 5.23782 14.9871 4.39616C14.0402 3.55451 12.8954 2.96645 11.6598 2.68686C10.4241 2.40727 9.13771 2.44527 7.92068 2.79729C6.70365 3.14932 5.59562 3.80391 4.7 4.69998L0.833336 8.33331M19.1667 11.6666L15.3 15.3C14.4044 16.1961 13.2964 16.8506 12.0793 17.2027C10.8623 17.5547 9.57592 17.5927 8.34024 17.3131C7.10456 17.0335 5.95984 16.4455 5.01289 15.6038C4.06595 14.7621 3.34764 13.6943 2.925 12.5"
                stroke="#07354A"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </IdmButton>
        </IdmHoverText>
      </IdmSuccessText>
    </div>

    <h5 class="section-subtitle">Advanced Security Settings</h5>

    <div class="section-checkbox-container">
      <IdmCheckbox
        :checked="signature"
        @input="setAdvancedSecurity('setSignature', $event.target.checked)"
      />
      <p>
        Add request signature
        <a
          href="https://developers.idomoo.com/docs/v3/cpon1odwihaa5-authentication#request-signing"
          target="_blank"
          >Learn more</a
        >
      </p>

      <div class="section-input-container">
        <p>Your public key</p>
        <IdmInput
          :placeholder="'https://'"
          :locked="!signature"
          :value="signatureURL"
          :error="!validSignature"
          @input="setAdvancedSecurity('setSignatureURL', $event.target.value)"
        />

        <span class="validation-error" v-show="!validSignature"
          >Public key URL is mandatory</span
        >
      </div>
    </div>

    <div class="section-checkbox-container">
      <IdmCheckbox
        :checked="reqEncryption"
        @input="setAdvancedSecurity('setReqEncryption', $event.target.checked)"
      />
      <p>
        Add request payload encryption
        <a
          href="https://developers.idomoo.com/docs/v3/cpon1odwihaa5-authentication#request-body-encryption"
          target="_blank"
          >Learn more</a
        >
      </p>
    </div>

    <div class="section-checkbox-container">
      <IdmCheckbox
        :checked="resEncryption"
        @input="setAdvancedSecurity('setResEncryption', $event.target.checked)"
      />
      <p>
        Add response payload encryption
        <a
          href="https://developers.idomoo.com/docs/v3/cpon1odwihaa5-authentication#response-body-encryption"
          target="_blank"
          >Learn more</a
        >
      </p>

      <div class="section-input-container">
        <p>Your public key</p>
        <IdmInput
          :placeholder="'https://'"
          :locked="!resEncryption"
          :value="resEncryptionURL"
          :error="!validEncryption"
          @input="setAdvancedSecurity('setResEncryptionURL', $event.target.value)"
        />

        <span class="validation-error" v-show="!validEncryption"
          >Public key URL is mandatory</span
        >
      </div>
    </div>

    <p class="section-text key">
      Idomoo's public key
      <br />
      <span>https://usa-api.idomoo.com/api/v3/oauth/jwks</span>
    </p>

    <h5 class="section-subtitle">IP Whitelisting</h5>

    <div
      class="ip-address-container"
      data-test="settings-ip-address"
      v-for="(iPAddress, i) in iPAddresses"
      :key="i"
    >
      <IdmInput
        v-for="(iPNum, j) in iPAddress"
        :key="j"
        :value="iPAddresses[i][j]"
        :error="invalidIPs.indexOf(i) !== -1 && invalidIPs.length > 0"
        @input="setIpAddress(i, j, $event)"
        @keypress="onlyNum"
        class="ip-address-input"
      />

      <IdmButton :btnType="'edit'" @click="removeIpAddress(i)">
        <IdmIcon name="trash" />
      </IdmButton>

      <span
        class="validation-error"
        v-show="invalidIPs.indexOf(i) !== -1 && invalidIPs.length > 0"
        >Each box can contain 1-3 digit numbers. All boxes must be filled.</span
      >
    </div>

    <IdmButton
      class="section-btn"
      :btnType="'secondary'"
      :text="'Add an IP address'"
      @click="addIpAddress"
      ><IdmIcon name="plus"
    /></IdmButton>
  </div>
</template>
<script>
import { ref, computed, watch } from 'vue';
import { store } from '@/store';
import generateAPI from '@/gql/generateAPI.js';

export default {
  props: {
    invalidIPs: Array,
    validSignature: Boolean,
    validEncryption: Boolean,
  },
  setup() {
    const loading = ref(false);
    const copied = ref(false);
    const showCopied = ref(0);
    const hideHover = ref(false);
    const showPopup = ref(false);

    const iPAddresses = computed(() => store.getters.getIPAddresses);

    const apiKey = computed(() => store.getters.getApiKey);
    const signature = computed(() => store.getters.getSignature);
    const signatureURL = computed(() => store.getters.getSignatureURL);
    const reqEncryption = computed(() => store.getters.getReqEncryption);
    const resEncryption = computed(() => store.getters.getResEncryption);
    const resEncryptionURL = computed(() => store.getters.getResEncryptionURL);

    let lastSignatureURL = '';
    let lastResEncryptionURL = '';

    watch(signature, (val) => {
      if (!val) {
        lastSignatureURL = signatureURL.value;
        store.commit('setSignatureURL', '');
      } else {
        store.commit('setSignatureURL', lastSignatureURL);
      }
    });

    watch(resEncryption, (val) => {
      if (!val) {
        lastResEncryptionURL = resEncryptionURL.value;
        store.commit('setResEncryptionURL', '');
      } else {
        store.commit('setResEncryptionURL', lastResEncryptionURL);
      }
    });

    function createKey() {
      loading.value = true;
      showPopup.value = false;

      generateAPI()
        .then((res) => {
          loading.value = false;
          store.commit('setApiKey', res.data.generateAPI.value);

          hideHover.value = true;

          setTimeout(() => {
            hideHover.value = false;
          }, 5000);
        })
        .catch((err) => {
          console.log('err: ', err);
        });
    }

    function copyKey() {
      console.log('apiKey: ', apiKey.value);
      (async () => {
        try {
          await navigator.clipboard.writeText(apiKey.value);

          copied.value = true;

          setTimeout(() => {
            copied.value = false;
          }, 5000);
        } catch (err) {
          console.error('Failed to copy: ', err);
        }
      })();
    }

    function setAdvancedSecurity(commitMutation, val) {
      store.commit(commitMutation, val);
    }

    function addIpAddress() {
      iPAddresses.value.push(['', '', '', '']);
      store.commit('setIPAddresses', iPAddresses.value);
    }

    function setIpAddress(i, j, e) {
      console.log('e.target._value: ', e.target._value);
      if (e.target._value.length <= 3) {
        iPAddresses.value[i][j] = e.target._value;
      }

      store.commit('setIPAddresses', iPAddresses.value);
    }

    function removeIpAddress(i) {
      iPAddresses.value.splice(i, 1);
      store.commit('setIPAddresses', iPAddresses.value);
    }

    function onlyNum(e) {
      e = e ? e : window.event;
      let charCode = e.which ? e.which : e.keyCode;

      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        e.preventDefault();
      } else {
        return true;
      }
    }

    return {
      apiKey,
      signature,
      signatureURL,
      reqEncryption,
      resEncryption,
      resEncryptionURL,
      loading,
      copied,
      showCopied,
      hideHover,
      showPopup,
      createKey,
      copyKey,
      iPAddresses,
      setAdvancedSecurity,
      addIpAddress,
      setIpAddress,
      removeIpAddress,
      onlyNum,
    };
  },
};
</script>
<style lang="scss" scoped>
.section-title {
  margin-bottom: 24px;
}

.section-btn {
  margin-top: 24px;
}

.section-text {
  &.learn {
    margin-bottom: 24px;
  }

  &.key {
    padding: 24px 0 56px;

    span {
      font-size: var(--p1);
      color: var(--primary-600);
      text-decoration: underline;
    }
  }
}

.section-input-container {
  position: relative;

  .validation-error {
    left: 140px;
  }
}

.api-key-container {
  display: flex;
  align-items: center;
  margin: 16px 0 32px;

  .api-btn {
    margin-left: 8px;
    transition: 200ms ease-in-out;

    &.copied {
      background-color: var(--success-600);
      pointer-events: none;

      svg path {
        stroke: #fff;
      }
    }

    &.loading {
      svg {
        animation: spin 0.8s ease-in-out 0.5s infinite;

        @keyframes spin {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(-180deg);
          }
        }
      }
    }

    &.hideHover {
      pointer-events: none;
    }
  }
}

.ip-address-container {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  position: relative;

  div.ip-address-input {
    width: 110px;
    display: flex;
    align-items: center;

    &:last-of-type {
      &::after {
        content: ' ';
      }
    }

    &::after {
      content: '.';
      width: 4px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-size: 16px;
      margin: 0 2px;
    }
  }
}

.validation-error {
  color: var(--alert-500);
  font-size: var(--p2);
  position: absolute;
  left: 0;
  bottom: -20px;
}
</style>
<style lang="scss">
.new-key-popup-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99999999;

  &::before {
    content: '';
    width: 100%;
    height: 100%;
    background-color: var(--dark-400);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0.5;
  }

  .idm-popup-container {
    background-color: #fff;
    position: relative;

    h3 {
      margin-bottom: initial;
    }
  }
}

.idm-input-container.hideHover .idm-input.k-input.secret-key {
  &::before {
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 18 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='Group 2473'%3E%3Ccircle id='Ellipse 108' cx='4' cy='4' r='3.25' stroke='%2307354A' stroke-width='1.5'/%3E%3Cline id='Line 178' x1='7.75' y1='3.25' x2='17.25' y2='3.25' stroke='%2307354A' stroke-width='1.5' stroke-linecap='round'/%3E%3Cline id='Line 179' x1='11.25' y1='7.25' x2='11.25' y2='3.75' stroke='%2307354A' stroke-width='1.5' stroke-linecap='round'/%3E%3Cline id='Line 180' x1='14.25' y1='7.25' x2='14.25' y2='3.75' stroke='%2307354A' stroke-width='1.5' stroke-linecap='round'/%3E%3C/g%3E%3C/svg%3E");
  }

  .k-input-inner {
    color: var(--success-600);
  }
}

.api-key-container .idm-input-container .idm-input.k-input.locked {
  background-image: initial;

  .k-input-inner {
    letter-spacing: 4px;
    text-overflow: initial;
  }
}
</style>
