<template>
  <IdmAuth>
    <div class="main-container" v-if="dataLoaded">
      <IdmHeader
        :userName="firstName + ' ' + lastName"
        :userMail="email"
        :userID="accountId"
        :profileImg="profileImg"
        :method="logOut"
      />

      <IdmSubHeader>
        <a href="#" target="_blank">
          <IdmLogo :app="'settings'" />
        </a>

        <IdmSuccessText
          :text="saveSuccess ? 'Settings saved successfully!' : saveUnsuccessfulMsg"
          :class="{ fail: !saveSuccess }"
          :visibilityTime="3000"
          :show="updateDataFinished"
        >
          <IdmButton
            :btnType="'primary'"
            :text="'Save'"
            class="save-btn"
            @click="saveData"
            data-test="settings-save-data"
          >
            <IdmLoader
              class="loader"
              :width="18"
              :height="18"
              type="dark"
              v-if="saveLoader"
            />
          </IdmButton>
        </IdmSuccessText>
      </IdmSubHeader>
      <div class="settings-container">
        <Navbar :sections="sections" />
        <div class="settings">
          <Profile id="sec-0" />
          <Account id="sec-1" />
          <APISettings
            id="sec-2"
            :validSignature="validSignature"
            :validEncryption="validEncryption"
            :invalidIPs="invalidIPs"
          />
        </div>
      </div>
    </div>
  </IdmAuth>
</template>

<script>
import { store } from '@/store';
import gql from 'graphql-tag';
import { provideApolloClient, useQuery } from '@vue/apollo-composable';
import updateData from '@/gql/updateData.js';

import NavBar from './components/NavBar';
import ProfileSec from './components/ProfileSec';
import AccountSec from './components/AccountSec';
import ApiSec from './components/ApiSec';

import { ref, computed, inject, watch } from 'vue';
import { isIPv4 } from 'is-ip';

export default {
  name: 'App',
  components: {
    Navbar: NavBar,
    Profile: ProfileSec,
    Account: AccountSec,
    APISettings: ApiSec,
  },
  setup() {
    const sections = ref(['Profile', 'Account', 'APISettings']);

    const invalidIPs = ref([]);

    const accountId = computed(() => store.getters.getAccountId);

    const firstName = computed(() => store.getters.getFirstName);

    const lastName = computed(() => store.getters.getLastName);

    const email = computed(() => store.getters.getEmail);

    const profileImg = computed(() => store.getters.getProfileImg);

    const signature = computed(() => store.getters.getSignature);
    const signatureURL = computed(() => store.getters.getSignatureURL);
    const resEncryption = computed(() => store.getters.getResEncryption);
    const resEncryptionURL = computed(() => store.getters.getResEncryptionURL);

    const dataLoaded = computed(() => store.getters.getDataLoaded);

    const validSignature = ref(true);
    const validEncryption = ref(true);

    const saveLoader = ref(false);
    const updateDataFinished = ref(0);
    const saveSuccess = ref(true);
    const saveUnsuccessfulMsg = ref('Save unsuccessful');

    /*----------------------------------------------------------------------*/

    const auth = inject('auth');

    function provideApollo() {
      const apolloClient = auth._apolloClient;

      provideApolloClient(apolloClient);

      console.log('apollo provided');
    }

    if (auth._initialized) {
      provideApollo();
    }
    watch(auth._initialized, (val) => {
      if (val) {
        provideApollo();
      }
    });

    /*----------------------------------------------------------------------*/

    const setData = useQuery(gql`
      query getUser {
        getUser {
          firstName
          lastName
          email
          twoStep
          twoStepKey
          company
          region
          profileImg
          logo
          accountId
          secretKey
          signature
          signatureURL
          reqEncryption
          resEncryption
          resEncryptionURL
          iPAddresses
        }
      }
    `).onResult;

    setData((res) => {
      console.log('res: ', res);

      store.commit('setFirstName', res.data.getUser.firstName);
      store.commit('setLastName', res.data.getUser.lastName);
      store.commit('setEmail', res.data.getUser.email);
      store.commit('setTwoStep', res.data.getUser.twoStep);
      store.commit('setTwoStepKey', res.data.getUser.twoStepKey);
      store.commit('setCompany', res.data.getUser.company);
      store.commit('setRegion', res.data.getUser.region);
      store.commit('setProfileImg', res.data.getUser.profileImg);
      store.commit('setLogo', res.data.getUser.logo);
      store.commit('setAccountId', res.data.getUser.accountId);
      store.commit('setApiKey', res.data.getUser.secretKey);
      store.commit('setSignature', res.data.getUser.signature);
      store.commit('setSignatureURL', res.data.getUser.signatureURL);
      store.commit('setReqEncryption', res.data.getUser.reqEncryption);
      store.commit('setResEncryption', res.data.getUser.resEncryption);
      store.commit('setResEncryptionURL', res.data.getUser.resEncryptionURL);

      if (res.data.getUser.iPAddresses)
        store.commit(
          'setIPAddresses',
          res.data.getUser.iPAddresses.map((IPAddress) => IPAddress.split('.'))
        );
      store.commit('setDataLoaded', true);
    });

    const getRenderingMinutes = useQuery(gql`
      query getRenderingMinutes {
        getRenderingMinutes {
          credits
        }
      }
    `).onResult;

    getRenderingMinutes((res) => {
      console.log('getCredits res: ', res);
      store.commit('setRenderingMin', res.data.getRenderingMinutes.credits);
    });

    let invalidIPsLocal;

    function iPValidation(iPArr) {
      invalidIPs.value = [];
      invalidIPsLocal = [];

      for (let i = 0; i < iPArr.length; i++) {
        if (!isIPv4(iPArr[i])) {
          invalidIPsLocal.push(i);
        }
      }

      if (invalidIPsLocal.length) {
        setTimeout(() => {
          invalidIPs.value = [...invalidIPsLocal];
        }, 3100);
      }
    }

    function isUrlValid(str) {
      try {
        new URL(str);
        return true;
      } catch (err) {
        return false;
      }
    }

    let validSignatureLocal;
    let validEncryptionLocal;

    function saveData() {
      validSignatureLocal = false;
      validEncryptionLocal = false;

      if (!(isUrlValid(signatureURL.value) || !signature.value)) {
        setTimeout(() => {
          validSignature.value = false;
        }, 3100);
      } else {
        validSignatureLocal = true;
        validSignature.value = true;
      }

      if (!(isUrlValid(resEncryptionURL.value) || !resEncryption.value)) {
        setTimeout(() => {
          validEncryption.value = false;
        }, 3100);
      } else {
        validEncryptionLocal = true;
        validEncryption.value = true;
      }

      const iPAddressesLocal = store.getters.getIPAddresses.map((IPAddress) => {
        return IPAddress.join('.');
      });

      iPValidation(iPAddressesLocal);

      if (invalidIPsLocal.length === 0 && validSignatureLocal && validEncryptionLocal) {
        const data = {
          firstName: store.getters.getFirstName,
          lastName: store.getters.getLastName,
          twoStep: store.getters.getTwoStep,
          company: store.getters.getCompany,
          profileImg: store.getters.getProfileImg,
          logo: store.getters.getLogo,
          iPAddresses: iPAddressesLocal,
          signature: store.getters.getSignature,
          signatureURL: store.getters.getSignatureURL,
          reqEncryption: store.getters.getReqEncryption,
          resEncryption: store.getters.getResEncryption,
          resEncryptionURL: store.getters.getResEncryptionURL,
        };

        saveLoader.value = true;

        updateData({ params: data })
          .then((res) => {
            console.log('res: ', res);
            saveSuccess.value = true;
            saveLoader.value = false;
            updateDataFinished.value++;
          })
          .catch((err) => {
            console.log('err: ', err);
            saveUnsuccessfulMsg.value = 'Save unsuccessful';
            saveSuccess.value = false;
            saveLoader.value = false;
            updateDataFinished.value++;
          });
      } else {
        saveUnsuccessfulMsg.value = 'Save unsuccessful, \n please review and correct';
        saveSuccess.value = false;
        updateDataFinished.value++;
        console.log('invalidIPs.value: ', invalidIPs.value);
      }
    }

    function logOut() {
      auth.logout();
    }

    return {
      sections,
      invalidIPs,
      accountId,
      firstName,
      lastName,
      email,
      profileImg,
      dataLoaded,
      validSignature,
      validEncryption,
      saveLoader,
      saveSuccess,
      saveUnsuccessfulMsg,
      updateDataFinished,
      saveData,
      logOut,
    };
  },
};
</script>

<style lang="scss" scoped>
.loader-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .loader {
    margin: auto;
  }
}
</style>
<style lang="scss">
* {
  padding: 0;
  margin: 0;
}

html,
body {
  scroll-behavior: smooth;
}

.main-container {
  width: 100%;
  height: auto;

  .idm-header,
  .idm-subheader {
    position: sticky;
  }

  .idm-header {
    top: 0;
    z-index: 999999;
  }

  .idm-subheader {
    top: 56px;
    z-index: 99999;

    a {
      pointer-events: none;
    }

    .idm-success {
      margin-left: auto;

      &.fail {
        .success-text {
          background-color: var(--alert-500);

          &::after {
            background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_470_1341)'%3E%3Cpath d='M10.0003 18.3333C14.6027 18.3333 18.3337 14.6024 18.3337 9.99999C18.3337 5.39762 14.6027 1.66666 10.0003 1.66666C5.39795 1.66666 1.66699 5.39762 1.66699 9.99999C1.66699 14.6024 5.39795 18.3333 10.0003 18.3333Z' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M6.66699 13.3333C6.66699 13.3333 7.91699 11.6667 10.0003 11.6667C12.0837 11.6667 13.3337 13.3333 13.3337 13.3333' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M12.5 7.5H12.5083' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M7.5 7.5H7.50833' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_470_1341'%3E%3Crect width='20' height='20' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
          }
        }
      }

      .success-text {
        height: 32px;
        max-width: initial;
        padding: 4px 12px 6px;
        border-radius: 8px;
        top: 52px;
        right: 0;
        transform: translateX(0%);

        &::after {
          background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_933_322)'%3E%3Cpath d='M10.0003 18.3333C14.6027 18.3333 18.3337 14.6024 18.3337 9.99999C18.3337 5.39762 14.6027 1.66666 10.0003 1.66666C5.39795 1.66666 1.66699 5.39762 1.66699 9.99999C1.66699 14.6024 5.39795 18.3333 10.0003 18.3333Z' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M6.66699 11.6667C6.66699 11.6667 7.91699 13.3333 10.0003 13.3333C12.0837 13.3333 13.3337 11.6667 13.3337 11.6667' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M12.5 7.5H12.5083' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M7.5 7.5H7.50833' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_933_322'%3E%3Crect width='20' height='20' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
        }
      }

      .idm-btn.save-btn {
        min-width: 88px;
        justify-content: center;
      }
    }
  }

  .idm-success {
    z-index: 2;
  }

  .settings-container {
    width: 100%;
    display: flex;
    align-items: stretch;

    .settings {
      width: 100%;
      padding-left: 72px;

      .section-container {
        width: 504px;
        color: var(--dark-500);
        // padding: 134px 0 56px;
        padding: 32px 0 56px;
        // position: relative;
        // top: -102px;

        &:not(:last-child) {
          border-bottom: 1px solid var(--natural-100);
        }

        .section-title,
        .section-subtitle {
          font-weight: 700;
        }

        .section-title {
          font-size: 23px;
          margin-bottom: 32px;
        }

        .section-subtitle {
          font-size: var(--p2);
          margin-bottom: 8px;
        }

        .section-checkbox-container {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          padding: 24px 0;
          border-bottom: 1px solid var(--natural-100);

          > p {
            margin-left: 8px;
          }

          .section-input-container {
            width: 100%;
            display: flex;
            align-items: center;
            margin-top: 10px;

            > p {
              line-height: 0.7;
              flex-shrink: 0;
              margin-right: 8px;
              margin-left: 24px;
            }
          }
        }

        .idm-input-wrapper:not(:last-child) {
          margin-bottom: 16px;
        }

        a {
          color: var(--primary-600);
        }
      }
    }
  }
}
</style>
