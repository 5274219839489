import { createStore } from 'vuex'

export const store = createStore({
    state: {
        accountId: '',
        firstName: '',
        lastName: '',
        email: '',
        twoStep: false,
        twoStepKey: '',
        company: '',
        region: '',
        renderingMin: 0,
        profileImg: '',
        logo: '',
        apiKey: '',
        iPAddresses: [],
        signature: false,
        signatureURL: '',
        reqEncryption: false,
        resEncryption: false,
        resEncryptionURL: '',
        dataLoaded: false,
    },
    mutations: {
        setAccountId (state, payload) {
            state.accountId = payload;
        },
        setFirstName (state, payload) {
            state.firstName = payload;
        },
        setLastName (state, payload) {
            state.lastName = payload;
        },
        setEmail (state, payload) {
            state.email = payload;
        },
        setTwoStep (state, payload) {
            state.twoStep = payload;
        },
        setTwoStepKey (state, payload) {
            state.twoStepKey = payload;
        },
        setCompany (state, payload) {
            state.company = payload;
        },
        setRegion (state, payload) {
            state.region = payload;
        },
        setRenderingMin (state, payload) {
            state.renderingMin = payload;
        },
        setProfileImg (state, payload) {
            state.profileImg = payload;
        },
        setLogo (state, payload) {
            state.logo = payload;
        },
        setApiKey (state, payload) {
            state.apiKey = payload;
        },
        setIPAddresses (state, payload) {
            state.iPAddresses = payload;
        },
        setSignature (state, payload) {
            state.signature = payload;
        },
        setSignatureURL (state, payload) {
            state.signatureURL = payload;
        },
        setReqEncryption (state, payload) {
            state.reqEncryption = payload;
        },
        setResEncryption (state, payload) {
            state.resEncryption = payload;
        },
        setResEncryptionURL (state, payload) {
            state.resEncryptionURL = payload;
        },
        setDataLoaded (state, payload) {
            state.dataLoaded = payload;
        },
    },
    getters: {
        getAccountId (state) {
            return state.accountId;
        },
        getFirstName (state) {
          return state.firstName;
        },
        getLastName (state) {
            return state.lastName;
        },
        getEmail (state) {
            return state.email;
        },
        getTwoStep (state) {
            return state.twoStep;
        },
        getTwoStepKey (state) {
            return state.twoStepKey;
        },
        getCompany (state) {
            return state.company;
        },
        getRegion (state) {
            return state.region;
        },
        getRenderingMin (state) {
            return state.renderingMin;
        },
        getProfileImg (state) {
            return state.profileImg;
        },
        getLogo (state) {
            return state.logo;
        },
        getApiKey (state) {
            return state.apiKey
        },
        getIPAddresses (state) {
            return state.iPAddresses;
        },
        getSignature (state) {
            return state.signature;
        },
        getSignatureURL (state) {
            return state.signatureURL;
        },
        getReqEncryption (state) {
            return state.reqEncryption;
        },
        getResEncryption (state) {
            return state.resEncryption;
        },
        getResEncryptionURL (state) {
            return state.resEncryptionURL;
        },
        getDataLoaded (state) {
            return state.dataLoaded;
        },
    }
})